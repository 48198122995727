import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'

export default function useUsersList() {
  const isCollapsedCard = ref({
    pkh: false,
    dpc: false,
  })
  const isPopupUserModal = ref(false)
  const userModalMode = ref('create')
  const userModalData = ref({})
  const users = ref([])
  const usersOrigin = ref([])
  const roles = ref([])
  const departments = ref([])
  const roleFilter = ref('')
  const searchQuery = ref('')
  const departmentFilter = ref('')

  // Table Handlers
  const tableColumns = [
    {
      label: 'Người dùng',
      key: 'nguoi-dung',
    },
    {
      label: 'Nhóm người dùng',
      key: 'nhom-nguoi-dung',
    },
    {
      label: 'Phòng ban',
      key: 'phong-ban',
    },
    {
      label: 'Trạng thái',
      key: 'trang-thai',
    },
    {
      label: '',
      key: 'actions',
    },
  ]
  const perPage = ref(10)
  const currentPage = ref(1)
  const totalContracts = ref(0)
  const perPageOptions = [10, 25, 50, 100]
  const resultTitle = ref('')

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'primary'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const fetchUsers = filter => {
    const queryString = http.buildQuery(filter || {
      page: 0,
      perpage: 999,
    }) || ''
    return http.api(store.dispatch('user/fetchUsers', queryString), res => {
      usersOrigin.value = res.data || []
    })
  }

  const fetchRoles = filter => {
    const queryString = http.buildQuery(filter || {
      page: 0,
      perpage: 999,
    }) || ''
    return http.api(store.dispatch('role/fetchRoles', queryString), res => {
      roles.value = res.data || []
    })
  }

  const fetchDepartments = filter => {
    const queryString = http.buildQuery(filter || {
      page: 0,
      perpage: 999,
    }) || ''
    return http.api(store.dispatch('department/fetchDepartments', queryString), res => {
      departments.value = res.data || []
    })
  }

  const fetchUser = id => http.api(store.dispatch('user/fetchUser', { id }), res => {
    userModalData.value = res.data
    isPopupUserModal.value = true
  })

  const updateUser = data => http.api(store.dispatch('user/updateUser', data), res => {
    userModalData.value = res.data
    Object.assign(users.value.find(u => u.id === res.data.id), res.data)
  })

  const createUser = data => http.api(store.dispatch('user/createUser', data), res => {
    isPopupUserModal.value = false
    users.value.unshift(res.data)
  })

  const deleteUser = user => http.api(store.dispatch('user/deleteUser', user), () => {
    const idx = users.value.findIndex(u => u.id === user.id)
    users.value.splice(idx, 1)
  })

  const filterUsers = () => {
    if (!departmentFilter.value && !roleFilter.value && !searchQuery.value) {
      users.value = usersOrigin.value
      return
    }
    users.value = usersOrigin.value.filter(u => {
      const r = roleFilter.value?.toLowerCase()
      const d = departmentFilter.value?.toLowerCase()
      const q = searchQuery.value?.toLowerCase()
      if (r && (u.user_role?.code?.toLowerCase() === r)) { return true }
      if (d && (u.department?.code?.toLowerCase() === d)) { return true }
      return q && (u.fullname?.toLowerCase().includes(q) || u.phone?.toLowerCase().includes(q) || u.email?.toLowerCase().includes(q))
    })
  }

  const searchKey = () => {
    filterUsers()
  }

  watch([departmentFilter, roleFilter, usersOrigin], () => {
    filterUsers()
  })

  return {
    tableColumns,
    perPage,
    currentPage,
    totalContracts,
    perPageOptions,
    resultTitle,
    fetchUsers,
    fetchUser,
    updateUser,
    createUser,
    deleteUser,
    isCollapsedCard,
    users,
    usersOrigin,
    roles,
    fetchRoles,
    searchQuery,
    searchKey,
    roleFilter,
    departmentFilter,
    fetchDepartments,
    departments,
    isPopupUserModal,
    userModalMode,
    userModalData,
    resolveUserRoleVariant,
  }
}
